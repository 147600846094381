p {
  text-indent: 25px;
}

@import url("https://fonts.googleapis.com/css?family=Raleway:600");

@import url("https://fonts.googleapis.com/css?family=Roboto");

@charset "UTF-8";

body.fancybox-active {
  overflow: hidden;
}

body.fancybox-iosfix {
  position: fixed;
  left: 0;
  right: 0;
}

.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99992;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption-wrap {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0s linear 0.25s;
  box-sizing: border-box;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s, visibility 0s;
}

.fancybox-infobar {
  top: 0;
  left: 0;
  font-size: 13px;
  padding: 0 10px;
  height: 44px;
  min-width: 44px;
  line-height: 44px;
  color: #ccc;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
  mix-blend-mode: exclusion;
}

.fancybox-toolbar {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translate3d(0, 0, 0);
}

.fancybox-is-closing .fancybox-stage {
  overflow: visible;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: visible;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
  background: #000;
}

.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
  background: #e5e3df;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide > title,
.fancybox-slide > style,
.fancybox-slide > meta,
.fancybox-slide > link,
.fancybox-slide > script,
.fancybox-slide > base {
  display: none;
}

.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff;
}

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.fancybox-error {
  margin: 0;
  padding: 40px;
  width: 100%;
  max-width: 380px;
  background: #fff;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 16px;
  line-height: 20px;
}

.fancybox-button {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 10px;
  border: 0;
  border-radius: 0;
  background: rgba(30,30,30,0.6);
  transition: color 0.3s ease;
  cursor: pointer;
  outline: none;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:focus,
.fancybox-button:hover {
  color: #fff;
}

.fancybox-button[disabled] {
  color: #ccc;
  cursor: default;
  opacity: 0.6;
}

.fancybox-button svg {
  display: block;
  position: relative;
  overflow: visible;
  shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3;
}

.fancybox-button--share svg path {
  stroke-width: 1;
}

.fancybox-button--play svg path:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg path:nth-child(1) {
  display: none;
}

.fancybox-button--zoom svg path {
  fill: transparent;
}

.fancybox-navigation {
  display: none;
}

.fancybox-show-nav .fancybox-navigation {
  display: block;
}

.fancybox-navigation button {
  position: absolute;
  top: 50%;
  margin: -50px 0 0 0;
  z-index: 99997;
  background: transparent;
  width: 60px;
  height: 100px;
  padding: 17px;
}

.fancybox-navigation button:before {
  content: "";
  position: absolute;
  top: 30px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: rgba(30,30,30,0.6);
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 0;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.fancybox-close-small:after {
  content: '×';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 22px/30px Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background-color: transparent;
  transition: background-color 0.25s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus {
  outline: none;
}

.fancybox-close-small:focus:after {
  outline: 1px dotted #888;
}

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee;
}

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
  top: 0;
  right: -40px;
}

.fancybox-slide--image .fancybox-close-small:after,
.fancybox-slide--iframe .fancybox-close-small:after {
  font-size: 35px;
  color: #aaa;
}

.fancybox-slide--image .fancybox-close-small:hover:after,
.fancybox-slide--iframe .fancybox-close-small:hover:after {
  color: #fff;
  background: transparent;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none;
}

.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 2vw 0 2vw;
  background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.1) 20%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.8) 100%);
  pointer-events: none;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255,255,255,0.4);
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
  pointer-events: all;
  position: relative;
  /* Fix IE11 */
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

.fancybox-slide > .fancybox-loading {
  border: 6px solid rgba(100,100,100,0.4);
  border-top: 6px solid rgba(255,255,255,0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate 0.8s infinite linear;
  animation: fancybox-rotate 0.8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

.fancybox-share {
  padding: 30px;
  border-radius: 3px;
  background: #f4f4f4;
  max-width: 90%;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  margin: 0 0 20px 0;
  font-size: 35px;
  font-weight: 700;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

p.fancybox-share__links {
  margin-right: -10px;
}

.fancybox-share__button {
  display: inline-block;
  text-decoration: none;
  margin: 0 10px 10px 0;
  padding: 0 15px;
  min-width: 130px;
  border: 0;
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  transition: all 0.2s;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  position: relative;
  top: -1px;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  vertical-align: middle;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 10px 15px;
  background: transparent;
  color: #5d5b5b;
  font-size: 14px;
  outline: none;
  border: 0;
  border-bottom: 2px solid #d7d7d7;
}

.fancybox-thumbs {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 212px;
  margin: 0;
  padding: 2px 2px 4px 2px;
  background: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
  white-space: nowrap;
}

.fancybox-thumbs-x > ul {
  overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  padding: 0;
  margin: 2px;
  width: 100px;
  height: 75px;
  max-width: calc(50% - 4px);
  max-height: calc(100% - 8px);
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0,0,0,0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

@-moz-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-o-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
}

.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
}

.checkbox + label {
  cursor: pointer;
}

.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}

.checkbox:not(checked) + label {
  position: relative;
  /* будем позиционировать псевдочекбокс относительно label */
  padding: 0 0 0 35px;
  /* оставляем слева от label место под псевдочекбокс */
}

.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #dce2ea;
  background: $fff;
}

.checkbox:not(checked) + label:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-coclor: #fff;
  background-image: none;
  background-position: 50% 50%;
  transition: all 0.2s;
  /* анимация, чтобы чекбокс переключался плавно */
}

.checkbox:checked + label:after {
  background: #fedb3a url("/img/checkbox__bg.png") no-repeat 50% 50%;
}

.select {
  border: 1px solid #c6c4c4;
  border-radius: 4px;
  background: linear-gradient(to bottom, #fefefe, #f2f4f7);
  font-size: 14px;
  padding: 5px 10px;
}

.button {
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
  outline: none;
  border-radius: 4px;
  transition: background 0.25s;
  margin: 0;
  background-color: #c6c4c4;
  display: inline-block;
}

.button__icon {
  position: relative;
  width: 28px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  background: 50% no-repeat;
  background-image: url("/img/button__img--cart.png");
}

.button__icon::after {
  visibility: hidden;
  content: '\00A0';
}

.button__text {
  position: relative;
  display: inline-block;
  line-height: 20px;
}

.button::before {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  content: '';
  border-radius: 4px;
  background: #fedb3a;
  transition: background 0.25s;
}

.button:hover::before {
  background: #fcc532;
}

.button--white::before {
  background: #fff;
}

.button--white:hover::before {
  background: #fff;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}

.button--white:hover {
  background: #fedb3a;
}

.button--left::before {
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.button--right::before {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button--transparent {
  background: #fff;
}

.buttonMore__link {
  border-radius: 4px;
  color: #3a3a3a;
  display: inline-block;
  text-decoration: none;
  transition: background 0.25s;
}

.buttonMore__link--comments {
  background: #fedb3a;
  text-transform: uppercase;
  padding: 15px 40px;
  line-height: 32px;
  height: 32px;
  line-height: 32px;
  height: 32px;
  font-size: 24px;
}

.buttonMore__link--miniCart {
  background: #fedb3a;
  padding: 5px 10px;
}

.buttonMore__link:hover {
  background: #fcc532;
}

.buttonMore__text {
  display: inline-block;
  text-decoration: none;
}

.buttonMore__ico {
  display: inline-block;
  line-height: 32px;
  height: 32px;
  width: 32px;
  font-size: 24px;
  background-repeat: no-repeat;
  background-image: url("/img/more__ico.png");
}

.buttonMore__ico::after {
  visibility: hidden;
  content: '\00A0';
}

.input {
  position: relative;
  display: inline-block;
  margin-left: 1px;
}

.input__label {
  display: block;
}

.input__box {
  display: block;
  cursor: text;
  position: relative;
  box-sizing: border-box;
}

.input__box::before {
  border: 1px solid #c6c4c4;
  border-radius: 4px;
  background-color: #fff;
  background-clip: padding-box;
  transition: 0.25s ease-out;
  transition-property: border-color, border-width;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  content: '';
}

.input__box--focus:before {
  border-color: #fedb3a;
  border-width: 2px;
}

.input__control {
  z-index: 3;
  width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: top;
  color: #000;
  border: 0 solid transparent;
  outline: 0;
  background: 0 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-appearance: none;
  position: relative;
  box-sizing: border-box;
  border-right-width: 10px;
  border-left-width: 10px;
  font-family: inherit;
}

.input__control--center {
  text-align: center;
}

.input__error {
  color: #ff5454;
  font-style: italic;
}

.img {
  font: 0/0 a;
  border: 0;
}

.link {
  text-decoration: none;
  color: #fedb3a;
}

.table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.article__paragraph {
  padding: 0;
  margin: 0;
}

.title {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-size: 24px;
  font-weight: bold;
}

.title--center {
  text-align: center;
  padding: 10px 0;
}

.titleLine {
  z-index: 1;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  padding: 5px 65px;
}

.titleLine--main {
  text-align: center;
}

.titleLine__text {
  display: inline-block;
  position: relative;
  font-size: 24px;
  color: #3a3a3a;
}

.titleLine__text::before {
  content: "";
  position: absolute;
  width: 55px;
  height: 2px;
  background: #fedb3a;
  left: -65px;
  top: 50%;
}

.titleLine__text::after {
  content: "";
  position: absolute;
  width: 55px;
  height: 2px;
  background: #fedb3a;
  right: -65px;
  top: 50%;
}

.titleLine__link {
  text-decoration: none;
}

.titleLine__xl {
  font-size: 30px;
}

.titleLine__md {
  font-size: 24px;
}

.debug {
  height: 100vh;
  position: fixed;
  width: 100%;
}

.debug > div {
  height: inherit;
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.debug > div > div {
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.debug > div > div > div {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(8.333333333333334% - 30px);
  height: inherit;
  outline: 1px solid #666;
  background: rgba(0,0,0,0.5);
}

.page--theme {
  font: 14px/2.14 'Roboto', sans-serif;
  min-height: 100vh;
  margin: 0;
  color: #3a3a3a;
  background: #fff;
  min-width: 100%;
  min-height: 100%;
}

.layout__topMenu {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__header {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__middleMenu {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__title {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__utpBlock {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__sliderTab {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__main {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__footer {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  margin-top: 100px;
}

.layout__breadcrumbs {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__content {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.layout__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.layout__cell {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
}

.layout__recProducts {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.line {
  width: 100%;
}

.line--bdr {
  border-bottom: 1px solid #c6c4c4;
}

.line--bgYellow {
  background: #fce3af;
}

.line--bgGray {
  background: #3a3a3a;
}

.header {
  padding-top: 40px;
  padding-bottom: 40px;
}

.header__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.header__logo {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  display: flex;
  text-align: center;
  align-self: center;
}

.header__address {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  align-self: center;
}

.header__phone {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  align-self: center;
  text-align: center;
}

.header__miniCart {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  align-self: center;
}

.topMenu__control {
  display: none;
  padding-left: 40px;
  background: url("/img/sandwich__ico.png") no-repeat 5px 50%;
  text-decoration: none;
  color: #3a3a3a;
  font-size: 18px;
  transition: color 0.25s;
}

.topMenu__control:hover {
  color: #fedb3a;
}

.topMenu__drop {
  background: #fff;
  z-index: 1003;
}

.topMenu__drop--hidden {
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.topMenu__drop--visible {
  height: auto;
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.topMenu__link {
  display: inline-block;
  text-decoration: none;
  padding: 0 15px;
  color: #3a3a3a;
  transition: background 0.25s;
}

.topMenu__link:hover {
  background: #fce3af;
}

.logo__img {
  display: block;
  margin: auto;
  max-width: 100%;
  min-width: 150px;
}

.address__line {
  position: relative;
  padding-left: 20px;
  font-size: 13px;
}

.address__line::before {
  content: "";
  background: url("/img/mapMark.png") no-repeat;
  position: absolute;
  height: 19px;
  width: 14px;
  left: 0;
}

.phone__day {
  border-bottom: 2px solid #fcc532;
  padding: 2px 5px;
}

.phone__line {
  display: block;
}

.phone--footer {
  text-decoration: underline;
}

.miniCart {
  display: flex;
  flex-wrap: wrap;
  min-height: 70px;
}

.miniCart__title {
  width: 100%;
  text-align: center;
}

.miniCart__image {
  align-self: center;
  position: relative;
  width: 50%;
  text-align: right;
}

.miniCart__img {
  max-width: 100%;
  align-self: center;
  display: inline-block;
  margin: auto;
}

.miniCart__totalItem {
  align-self: center;
  display: block;
  position: absolute;
  background: #fedb3a;
  border-radius: 30%;
  line-height: 14px;
  padding: 2px 7px;
  right: 47px;
}

.miniCart__info {
  align-self: center;
  width: 50%;
  text-align: center;
  line-height: 20px;
  position: relative;
}

.miniCart__delete {
  position: absolute;
  right: 0;
  top: -15px;
  width: 12px;
  height: 12px;
  background: url("/img/delete__ico--miniCart.png") no-repeat 0 0;
}

.pageForm__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.pageForm__content {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
}

.pageForm__form {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.middleMenu {
  position: relative;
  z-index: 1001;
}

.middleMenu__control {
  display: none;
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #fedb3a url("/img/sandwich__ico.png") no-repeat 5px 50%;
  text-decoration: none;
  color: #3a3a3a;
  font-size: 18px;
  transition: background-color 0.25s;
}

.middleMenu__control:hover {
  background-color: #fce3af;
}

.middleMenu__block {
  padding: 0;
  margin: 0;
  background: #fedb3a;
}

.middleMenu__submenu {
  position: absolute;
  padding: 0;
  background: #fce3af;
}

.middleMenu__item {
  display: inline-block;
}

.middleMenu__item .middleMenu__submenu {
  display: none;
}

.middleMenu__item:hover .middleMenu__submenu {
  display: block;
}

.middleMenu__item--level-2 {
  display: block;
}

.middleMenu__link {
  display: block;
  padding: 0 55px;
  color: #3a3a3a;
  text-decoration: none;
  background-color: #fcc532;
  font-size: 20px;
  line-height: 48px;
  transition: background 0.25s;
}

.middleMenu__link:hover {
  background: #fce3af;
}

.middleMenu__link--submenu:hover {
  background: #fedb3a;
}

.utpBlock__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.utpBlock__item {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.utpBlock__img {
  width: 100%;
  height: auto;
}

.sliderTab__test {
  color: #f00;
}

.sliderTab__title {
  padding-bottom: 10px;
}

.sliderTab__inner {
  width: 100%;
  background: url("/img/pattern.png");
  position: relative;
}

.sliderTab__row--hidden {
  display: none;
}

.tabs__inner {
  display: flex;
  flex-wrap: wrap;
}

.tabs__item {
  width: 100px;
  text-align: center;
  border: 1px solid #fedb3a;
  text-transform: uppercase;
  transition: background 0.25s;
  cursor: pointer;
}

.tabs__item--active {
  background: #fedb3a;
}

.tabs__item:hover {
  background: #fedb3a;
}

.slick-track {
  display: flex;
  align-items: stretch;
}

.item {
  text-align: center;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.item__title {
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
}

.item__link {
  color: #3a3a3a;
  position: relative;
  display: block;
}

.item__img {
  max-width: 100%;
  display: block;
  margin: auto;
  max-height: 240px;
}

.item__price {
  position: relative;
  background: #fff;
  padding: 10px 0;
  display: block;
  width: 100%;
  font-size: 14px;
  align-self: center;
}

.article__title {
  padding-top: 30px;
  padding-bottom: 0px;
  display: block;
}

.footer {
  color: #fff;
}

.footer__item {
  width: calc(25% - 30px);
  padding-bottom: 20px;
}

.footer__title {
  font-size: 18px;
  border-bottom: 2px solid #fff;
  margin-bottom: 20px;
}

.footer__menuItem {
  display: block;
  color: #fff;
  transition: color 0.25s;
}

.footer__menuItem:hover {
  color: #fedb3a;
}

.footer__schedule {
  width: 100%;
}

.footer__logo {
  padding-top: 20px;
  display: block;
  margin: auto;
}

.footer__phone {
  display: block;
  text-align: center;
  margin: 30px auto;
}

.footer__mail {
  display: block;
  text-align: center;
  margin: 30px auto;
}

.schedule__day {
  padding-right: 5px;
  width: 50%;
}

.schedule__time {
  color: #fedb3a;
  text-align: right;
  width: 50%;
}

.schedule__value {
  font-style: italic;
}

.mail__link {
  font-size: 16px;
  text-decoration: none;
  color: #3a3a3a;
}

.mail__link--white {
  color: #fff;
}

.rubric__title {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.menuRubric__inner {
  background: url("/img/pattern.png");
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.menuRubric__item {
  width: calc(33.3333% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.menuRubric__title {
  text-align: center;
}

.menuRubric__titleLink {
  text-transform: uppercase;
  font-size: 16px;
  color: #3a3a3a;
  text-decoration: none;
  padding: 15px 0;
  display: block;
  transition: color 0.25s;
}

.menuRubric__titleLink:hover {
  color: #aaa;
}

.menuRubric__img {
  transition: transform 0.25s;
  max-height: 200px;
  display: block;
  margin: auto;
}

.menuRubric__img:hover {
  transform: scale(0.96);
}

.breadcrumbs__box {
  overflow-x: auto;
  list-style: none;
  padding: 10px 0;
  margin: 10px 0;
  display: flex;
}

.breadcrumbs__item {
  flex-shrink: 0;
  padding: 0 10px;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  transition: border-color 0.25s;
}

.breadcrumbs__item--first {
  padding-left: 0;
}

.breadcrumbs__item:hover {
  border-color: #fedb3a;
}

.breadcrumbs__link {
  color: #3a3a3a;
}

.breadcrumbs__link--first {
  color: #aaa;
}

.sidebar {
  width: calc(25% - 30px);
  position: relative;
}

.sidebar__box {
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #dce2ea;
  border-radius: 4px;
}

.disabled_filter {
  display: none;
}

.category {
  width: calc(75% - 30px);
}

.category__title {
  text-align: center;
}

.category-full {
  width: calc(100% - 30px);
}

.category-full__title {
  text-align: center;
}

.rub_full {
  width: calc(100% - 30px);
}

.rub_full__title {
  text-align: center;
}

.filter__control {
  display: block;
  text-align: center;
  font-size: 16px;
  padding: 5px 0;
  border-bottom: 1px solid #dce2ea;
}

.filter__drop {
  z-index: 1000;
  box-sizing: border-box;
}

.filter__drop--hidden {
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.filter__drop--visible {
  height: auto;
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: transparent;
}

.filter__block {
  display: block;
  margin: 10px;
  padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dce2ea;
}

.filter__head {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
}

.filter__row {
  width: 100%;
}

.filter__row--uiSlider {
  width: 100%;
}

.filter__row--inputSlider {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.filter__row.unactive {
  display: none;
}

.filter__boxButton {
  text-align: center;
  margin: 20px auto 50px;
}

.filter__button {
  padding: 5px 15px;
}

.ui-slider__input {
  width: 49%;
  display: inline-block;
}

.form {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.form-request {
  box-sizing: border-box;
  padding: 15px;
  background: #fce3af;
}

.form-request__textarea {
  width: 100%;
  margin-bottom: 20px;
}

.success {
  display: block;
  text-align: center;
  color: #4e6686;
  font-size: 16px;
  font-style: italic;
}

.relatedProduct__inner {
  display: flex;
  flex-direction: column;
}

.relatedProduct__control {
  display: block;
  text-align: center;
  font-size: 16px;
  padding: 5px 0;
  border-bottom: 1px solid #dce2ea;
}

.relatedProduct__drop {
  z-index: 1000;
  box-sizing: border-box;
}

.relatedProduct__drop--hidden {
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.relatedProduct__drop--visible {
  height: auto;
  max-height: 1100px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: transparent;
}

.certificates__img {
  max-width: 100%;
}

.certificates__control {
  display: block;
  text-align: center;
  font-size: 16px;
  padding: 5px 0;
  border-bottom: 1px solid #dce2ea;
}

.certificates__drop {
  z-index: 1000;
  box-sizing: border-box;
}

.certificates__drop--hidden {
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.certificates__drop--visible {
  height: auto;
  max-height: 1100px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: transparent;
}

.dignity {
  padding: 20px 0;
}

.dignity__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dignity__item {
  width: 16%;
  position: relative;
  cursor: help;
}

.dignity__img {
  display: block;
  max-width: 100%;
  margin: auto;
}

.dignity__title {
  text-align: center;
  line-height: 20px;
}

.dignity__description {
  background: #ccc;
  border: 2px solid #fedb3a;
  border-radius: 4px;
  z-index: 9999;
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 250px;
  background-color: #fff;
  padding: 5px 10px;
  box-shadow: 0 1px 2px #555;
}

.dignity__description--hidden {
  display: none;
}

.dignity__description--visible {
  display: block;
}

.sorting {
  padding: 20px 0;
}

.sorting__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.sorting__item {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.sorting__label {
  display: block;
  color: #c6c4c4;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.list__snippet {
  margin-bottom: 20px;
}

.snippet {
  width: calc(33.333333333333336% - 30px);
  border: 1px solid #c6c4c4;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(198,196,196,0.75);
}

.snippet--related {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  margin-top: 10px;
  margin-bottom: 10px;
}

.snippet__img {
  max-width: calc(100% - 3px);
  max-height: 250px;
  display: block;
  margin: auto;
  padding: 3px;
  box-sizing: border-box;
  transition: transform 0.25s;
}

.snippet__img:hover {
  transform: scale(0.992);
}

.snippet__discount {
  position: absolute;
  bottom: 0;
  left: 5px;
  z-index: 1001;
}

.snippet__title {
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
}

.snippet__link {
  position: relative;
  display: block;
  color: #3a3a3a;
  text-decoration: none;
}

.snippet__box {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5px 20px;
}

.snippet__price {
  position: relative;
  font-size: 20px;
  width: 50%;
  text-align: center;
}

.snippet__button {
  width: 50%;
  line-height: 40px;
  text-align: center;
}

.discount__value {
  font-size: 18px;
  font-weight: bold;
  color: #4e6686;
  background: #fedb3a;
  padding: 5px 10px;
}

.price__old {
  font-size: 14px;
  color: #c6c4c4;
  text-decoration: line-through;
}

.price__old--snippet {
  position: absolute;
  top: -12px;
}

.price__old--item {
  position: absolute;
  z-index: 1001;
  top: -15px;
  left: -10px;
}

.price__value {
  position: relative;
  display: inline-block;
}

.price__value--item {
  font-size: 16px;
}

.pagination__inner {
  margin: 0;
  padding: 30px 0;
  text-align: center;
}

.pagination__item {
  display: inline-block;
  list-style: none;
  border: 1px solid #aaa;
  border-right: none;
  margin-right: -4px;
  padding-left: 0;
  transition: background 0.25s;
}

.pagination__item:first-child {
  border-radius: 3px 0 0 3px;
}

.pagination__item:last-child {
  border-radius: 0 3px 3px 0;
  border: 1px solid #aaa;
}

.pagination__item:hover {
  background: #fedb3a;
}

.pagination__link {
  color: #aaa;
  display: block;
  padding: 0 10px;
  transition: background 0.25s;
}

.pagination__link:hover {
  background: #fedb3a;
}

.pagination__active {
  background: #fce3af;
  display: block;
  padding: 0 10px;
}

.pagination__item:before {
  display: none;
}

.product__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.product__gallery {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.product__offer {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
}

.product__title {
  font-size: 30px;
}

.product__article {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.product__price {
  font-size: 24px;
}

.product__modification {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
}

.product__description {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: 100%;
}

.product__spec {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: 100%;
}

.product__looked {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: 100%;
}

.gallery__img {
  max-width: 100%;
  max-height: 400px;
  display: block;
  margin: auto;
}

.gallery__thumbItem {
  display: inline-block;
  width: 24%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.gallery__thumbsImg {
  max-width: 100%;
}

.offer__button {
  margin: 10px 0 20px 0;
  font-size: 16px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
}

.callback__message {
  margin-bottom: 5px;
}

.callback__messageText {
  box-sizing: border-box;
  border-bottom: 2px solid #fedb3a;
  transition: border-color 0.25s;
  padding-bottom: 2px;
}

.callback__input {
  width: 50%;
  display: block;
}

.callback__control {
  font-size: 16px;
  height: 38px;
  line-height: 38px;
}

.modification__list {
  display: flex;
  flex-wrap: wrap;
}

.modification__item {
  width: 25%;
}

.modification__title {
  flex-grow: 0;
}

.modification__pagetitle {
  font-size: 14px;
  line-height: 20px;
}

.modification__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modification__img {
  max-height: 200px;
}

.modification__img--icon {
  align-self: center;
}

.recProducts__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.recProducts__item {
  width: calc(16.666666666666668% - 30px);
}

.spec {
  max-width: 700px;
}

.spec__row {
  cursor: pointer;
}

.spec__row:hover {
  background: #fce3af;
}

.spec__name {
  border: 1px solid #c6c4c4;
  width: 70%;
  padding: 15px 5px 15px 35px;
}

.spec__value {
  border: 1px solid #c6c4c4;
  width: 30%;
  padding: 15px 5px 15px 35px;
}

.checkoutOffers {
  margin-bottom: 50px;
}

.checkoutOffers__checkoutOffer {
  margin-bottom: 10px;
}

.checkoutOffer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  border: 1px solid #c6c4c4;
  border-radius: 4px;
}

.checkoutOffer__item {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  align-self: center;
}

.checkoutOffer__item--type--number {
  width: calc(8.333333333333334% - 30px);
}

.checkoutOffer__item--type--img {
  width: calc(25% - 30px);
  padding: 0;
  box-sizing: border-box;
}

.checkoutOffer__item--type--content {
  width: calc(41.66666666666667% - 30px);
}

.checkoutOffer__item--type--count {
  width: calc(16.666666666666668% - 30px);
}

.checkoutOffer__item--type--del {
  width: calc(8.333333333333334% - 30px);
  align-self: flex-start;
}

.checkoutOffer__number {
  background: #fedb3a;
  border: 1px solid #fedb3a;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}

.checkoutOffer__img {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 200px;
  height: auto;
  display: block;
  margin: 0;
  padding: 15px;
}

.checkoutOffer__link {
  text-decoration: none;
  color: #3a3a3a;
}

.checkoutOffer__itemName {
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
}

.checkoutOffer__itemPrice {
  font-size: 24px;
}

.checkoutOffer__delete {
  position: relative;
}

.order .shk_count_button {
  display: none;
}

.amountSelect {
  display: inline-block;
}

.amountSelect__button {
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 38px;
  line-height: 38px;
  width: 28px;
}

.amountSelect__input {
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 38px;
  line-height: 38px;
  width: 52px;
  border-radius: none;
}

.amountSelect__control {
  height: 38px;
  line-height: 38px;
  text-align: center;
}

.imageDelete {
  background: url("/img/delete__ico.png") no-repeat 0 0;
  height: 21px;
  width: 21px;
  display: block;
  position: absolute;
  right: 0px;
  top: 10px;
}

.checkoutOrder {
  display: block;
}

.checkoutOrder__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: flex-end;
}

.checkoutOrder__formOrder {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.checkoutOrder__input {
  width: 100%;
}

.checkoutOrder__fullPrice {
  display: block;
  text-align: right;
  font-size: 24px;
}

.checkoutOrder__button {
  width: 100%;
  padding: 15px 0;
}

.comments__total {
  font-size: 24px;
}

.comments__more {
  margin: 50px 0;
}

.comments__buttonMore {
  text-align: center;
}

.comment__box {
  background: url("/img/pattern.png");
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin: 15px 0;
}

.comment__user {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(16.666666666666668% - 30px);
}

.comment__snippet {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(83.33333333333334% - 30px);
}

.comment__date {
  text-align: right;
}

.user__avatar {
  text-align: center;
}

.user__name {
  text-transform: uppercase;
  text-align: center;
  background: #fff;
}

.formComment__commentator {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.formComment__input {
  width: 80%;
  display: block;
  margin-bottom: 20px;
}

.formComment__area {
  width: 100%;
}

.formComment__controlArea {
  line-height: 24px;
}

.formComment__sum {
  width: 300px;
}

.formComment__label {
  font-weight: bold;
}

.formComment__control {
  line-height: 34px;
}

.formComment__message {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
  padding-bottom: 20px;
}

.formComment__submitBox {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  text-align: right;
  display: block;
  width: 100%;
}

.formComment__submit {
  font-size: 16px;
  padding: 15px 35px;
}

.contacts__inner {
  background: #ffc0cb;
}

.contacts__box {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  padding-bottom: 20px;
}

.contact__phone {
  width: calc(50% - 30px);
}

.contact__mail {
  width: calc(50% - 30px);
}

.contact__social {
  width: calc(50% - 30px);
}

.contact__schedule {
  width: calc(50% - 30px);
}

.contact__address {
  width: calc(50% - 30px);
}

.contact__title {
  font-size: 24px;
}

.social__line {
  display: block;
}

.img {
  display: block;
  max-width: 100%;
  height: auto;
}

.imgGall__inner {
  display: flex;
  flex-wrap: wrap;
}

.imgGall__item {
  max-width: calc(25% - 30px);
  padding: 15px;
}

.guaranteeImg {
  margin: auto;
}

.list-sinppet__inner {
  display: flex;
  flex-direction: column;
}

.list-sinppet__item {
  margin-bottom: 15px;
}

.list-sinppet-obj__inner {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  /*align-items: stretch
		align-content: stretch*/
}

.list-sinppet-obj__item {
  margin: 15px;
  width: calc(33.3333% - 30px);
}

.object-main__inner {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.object-main__item {
  height: 100%;
  min-height: 100%;
  margin: 15px;
  width: calc(33.3333% - 30px);
}

.question-item__inner {
  border: 1px solid #c6c4c4;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(198,196,196,0.75);
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.question-item__img-box {
  width: 340px;
  display: flex;
  justify-content: center;
}

.question-item__intro {
  width: calc(100% - 340px);
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.question-item__title {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  color: #3a3a3a;
  transition: color 0.25s;
}

.question-item__title:hover {
  color: #fedb3a;
}

.question-item__text {
  line-height: 20px;
}

.scontent__inner {
  display: flex;
  flex-direction: column;
}

.scontent__img-box {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.object-item__inner {
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border: 1px solid #c6c4c4;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(198,196,196,0.75);
  padding: 10px;
}

.object-item__title-box {
  text-align: center;
  font-size: 22px;
  color: #4e6686;
}

.object-item__intro {
  flex-grow: 1;
}

.object-item__table {
  width: 100%;
  line-height: 20px;
}

.object-item__table td {
  vertical-align: top;
  padding: 10px 0;
  border-bottom: 1px solid #c6c4c4;
}

.object-item__table td:first-child {
  font-weight: bold;
}

.object-item__table td:last-child {
  text-align: right;
  font-size: 13px;
}

.sslider {
  margin-bottom: 15px;
}

.slide-item__inner {
  position: relative;
  display: flex;
  /*&:after
			position absolute
			content ""
			top 0
			left 0
			bottom 0
			right 0
			width 100%
			height 100%
			background: rgba(0,0,0,0.4)*/
}

.slide-item__box {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 500px;
  height: 100%;
  background-color: #fedb3a;
}

.slide-item__img {
  object-fit: cover;
  height: 350px;
}

.slide-item__content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slide-item__content h2 {
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 15px 0;
  color: #4e6686;
}

.slide-item__content b {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin: 0 0 15px 0;
}

.slide-item__content p {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 10px 0;
}

.slide-item__content a {
  color: #4e6686;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  margin-bottom: 50px;
  margin-top: auto;
  background: #fce3af;
}

.content-text__img {
  float: left;
  margin-right: 15px;
}

.looked__wrap {
  background: url("/img/pattern.png");
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.looked__item {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.looked__name {
  color: #3a3a3a;
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
  padding: 2px 15px;
  background: #fff;
}

.looked__price {
  padding: 2px 15px;
  background: #fff;
  margin: 10px 0;
}

main ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

main ul li {
  position: relative;
  padding-left: 15px;
}

main ul li:before {
  position: absolute;
  top: -2px;
  left: 0;
  /*transform: translateY(-50%)*/
  content: "\25A0";
  color: #fedb3a;
  font-weight: bold;
  width: 2px;
  height: 2px;
}

p {
  text-indent: 25px;
}

.sq__inner {
  display: flex;
  justify-content: center;
  padding: 80px;
}

.sq__box {
  width: 320px;
  border: 2px solid #fedb3a;
  border-radius: 8px;
  text-align: center;
  padding: 15px;
}

.sq__title {
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__topMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__utpBlock {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__sliderTab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__breadcrumbs {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__recProducts {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__logo {
    width: calc(50% - 30px);
  }

  .header__logo {
    order: 0;
  }

  .header__logo {
    justify-content: center;
  }

  .header__address {
    width: calc(50% - 30px);
  }

  .header__address {
    order: 2;
  }

  .header__address {
    text-align: center;
  }

  .header__address {
    margin-top: 30px;
  }

  .header__phone {
    width: calc(50% - 30px);
  }

  .header__phone {
    order: 3;
  }

  .header__phone {
    margin-top: 30px;
  }

  .header__miniCart {
    width: calc(50% - 30px);
  }

  .header__miniCart {
    order: 1;
  }

  .topMenu__control {
    display: block;
  }

  .topMenu__drop {
    position: absolute;
  }

  .topMenu__drop--hidden {
    max-height: 0;
  }

  .topMenu__link {
    display: block;
  }

  .topMenu__link {
    background-color: #fff;
  }

  .topMenu__link {
    margin-bottom: 2px;
  }

  .topMenu__link {
    font-size: 16px;
  }

  .topMenu__link {
    padding-left: 40px;
  }

  .topMenu__link {
    padding-right: 30px;
  }

  .topMenu__link {
    border-width: 1px;
  }

  .topMenu__link {
    border-color: #c6c4c4;
  }

  .topMenu__link {
    border-style: solid;
  }

  .item__title {
    font-size: 14px;
  }

  .footer__item {
    width: calc(50% - 30px);
  }

  .sidebar {
    width: calc(100% - 30px);
  }

  .sidebar {
    display: flex;
  }

  .sidebar {
    flex-wrap: wrap;
  }

  .sidebar__box {
    width: 40%;
  }

  .sidebar__box {
    margin-right: 10%;
  }

  .sidebar__box {
    background: url("/img/pattern.png");
  }

  .sidebar__box--ob {
    width: 30%;
  }

  .sidebar__box--ob {
    margin-right: 3.3%;
  }

  .category {
    width: calc(100% - 30px);
  }

  .filter__control {
    display: block;
  }

  .filter__drop {
    background: #fff;
  }

  .filter__drop {
    position: absolute;
  }

  .filter__drop {
    width: 40%;
  }

  .filter__drop {
    margin-right: 10%;
  }

  .filter__drop--ob {
    width: 30%;
  }

  .filter__drop--ob {
    margin-right: 3.3%;
  }

  .filter__drop--hidden {
    max-height: 0;
  }

  .filter__drop--visible {
    border-color: #dce2ea;
  }

  .relatedProduct__inner {
    flex-direction: row;
  }

  .relatedProduct__control {
    display: block;
  }

  .relatedProduct__drop {
    background: #fff;
  }

  .relatedProduct__drop {
    position: absolute;
  }

  .relatedProduct__drop {
    width: 40%;
  }

  .relatedProduct__drop {
    margin-right: 10%;
  }

  .relatedProduct__drop--ob {
    width: 30%;
  }

  .relatedProduct__drop--ob {
    margin-right: 3.3%;
  }

  .relatedProduct__drop--hidden {
    max-height: 0;
  }

  .relatedProduct__drop--visible {
    border-color: #dce2ea;
  }

  .certificates__control {
    display: block;
  }

  .certificates__drop {
    background: #fff;
  }

  .certificates__drop {
    position: absolute;
  }

  .certificates__drop {
    width: 40%;
  }

  .certificates__drop {
    margin-right: 10%;
  }

  .certificates__drop--ob {
    width: 30%;
  }

  .certificates__drop--ob {
    margin-right: 3.3%;
  }

  .certificates__drop--hidden {
    max-height: 0;
  }

  .certificates__drop--visible {
    border-color: #dce2ea;
  }

  .callback__input {
    width: 100%;
  }

  .checkoutOffer__img {
    padding: 0;
  }

  .checkoutOffer__itemName {
    font-size: 24px;
  }

  .checkoutOffer__itemPrice {
    font-size: 20px;
  }

  .amountSelect__button {
    width: 20px;
  }

  .amountSelect__input {
    width: 42px;
  }

  .checkoutOrder__formOrder {
    width: calc(33.333333333333336% - 30px);
  }

  .formComment__submitBox {
    text-align: center;
  }

  .imgGall__item {
    max-width: calc(50% - 30px);
  }

  .list-sinppet-obj__item {
    width: calc(50% - 30px);
  }

  .object-main__item {
    width: calc(50% - 30px);
  }

  .looked__item {
    width: calc(50% - 30px);
  }
}

@media all and (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }

  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px);
  }
}

@media screen and (max-width: 768px) {
  .titleLine__text {
    font-size: 20px;
  }

  .titleLine__text {
    display: block;
  }

  .titleLine__text {
    text-align: center;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__topMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__utpBlock {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__sliderTab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__breadcrumbs {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__recProducts {
    padding-left: 15px;
    padding-right: 15px;
  }

  .phone__line {
    font-size: 13px;
  }

  .middleMenu__control {
    display: block;
  }

  .middleMenu__block {
    position: absolute;
  }

  .middleMenu__block--hidden {
    display: none;
  }

  .middleMenu__submenu {
    border: 2px solid #fedb3a;
  }

  .middleMenu__submenu {
    position: relative;
  }

  .middleMenu__submenu {
    z-index: 1001;
  }

  .middleMenu__submenu {
    margin-left: 0;
  }

  .middleMenu__item {
    display: block;
  }

  .middleMenu__item .middleMenu__submenu {
    display: block;
  }

  .middleMenu__link {
    padding-left: 40px;
  }

  .middleMenu__link {
    line-height: 28px;
  }

  .middleMenu__link--submenu {
    padding-left: 55px;
  }

  .utpBlock__item {
    width: calc(50% - 30px);
  }

  .main {
    padding-bottom: 30px;
  }

  .footer__item {
    width: calc(100% - 30px);
  }

  .footer__item {
    text-align: center;
  }

  .menuRubric__item {
    width: calc(10product (__inside0 % [object Object]));
  }

  .dignity__item {
    width: 15%;
  }

  .dignity__item {
    width: 30%;
  }

  .dignity__item {
    padding-top: 10px;
  }

  .dignity__item {
    padding-bottom: 10px;
  }

  .dignity__title {
    font-size: 13px;
  }

  .snippet__price {
    width: 100%;
  }

  .snippet__button {
    width: 100%;
  }

  .product__title {
    text-align: left;
  }

  .modification__item {
    width: 33.3333%;
  }

  .checkoutOffer__item--type--number {
    display: none;
  }

  .checkoutOffer__item--type--number {
    width: calc(0% - 30px);
  }

  .checkoutOffer__item--type--img {
    width: calc(16.666666666666668% - 30px);
  }

  .checkoutOffer__item--type--content {
    width: calc(50% - 30px);
  }

  .checkoutOffer__item--type--count {
    width: calc(25% - 30px);
  }

  .checkoutOffer__item--type--del {
    width: calc(8.333333333333334% - 30px);
  }

  .checkoutOffer__itemName {
    font-size: 22px;
  }

  .checkoutOffer__itemPrice {
    font-size: 18px;
  }

  .checkoutOrder__inner {
    justify-content: center;
  }

  .checkoutOrder__formOrder {
    width: calc(50% - 30px);
  }

  .comment__box {
    margin-bottom: 50px;
  }

  .formComment__commentator {
    width: calc(100% - 30px);
  }

  .formComment__message {
    width: calc(100% - 30px);
  }

  .formComment__submitBox {
    width: calc(100% - 30px);
  }

  .contact__phone {
    width: calc(100% - 30px);
  }

  .contact__mail {
    width: calc(100% - 30px);
  }

  .contact__social {
    width: calc(100% - 30px);
  }

  .contact__schedule {
    width: calc(100% - 30px);
  }

  .contact__address {
    width: calc(100% - 30px);
  }

  .question-item__inner {
    flex-direction: column;
  }

  .question-item__inner {
    align-items: center;
  }

  .question-item__img-box {
    width: 100%;
  }

  .question-item__intro {
    width: 100%;
  }

  .question-item__title-box {
    text-align: center;
  }

  .sslider {
    display: none;
  }

  .looked__item {
    width: calc(100% - 30px);
  }

  .sq__inner {
    padding: 40px;
  }
}

@media screen and (max-width: 576px) {
  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__topMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__utpBlock {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__sliderTab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__breadcrumbs {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__recProducts {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sidebar__box {
    width: 100%;
  }

  .sidebar__box {
    margin-right: 0;
  }

  .filter__drop {
    width: 100%;
  }

  .filter__drop {
    margin-right: 0;
  }

  .relatedProduct__inner {
    flex-direction: column;
  }

  .relatedProduct__drop {
    width: 100%;
  }

  .relatedProduct__drop {
    margin-right: 0;
  }

  .certificates__drop {
    width: 100%;
  }

  .certificates__drop {
    margin-right: 0;
  }

  .snippet {
    width: calc(50% - 30px);
  }

  .product__gallery {
    width: calc(100% - 30px);
  }

  .product__offer {
    width: calc(100% - 30px);
  }

  .product__title {
    text-align: center;
  }

  .product__article {
    text-align: center;
  }

  .product__price {
    text-align: center;
  }

  .offer {
    text-align: center;
  }

  .modification__item {
    width: 50%;
  }

  .spec__name {
    width: 60%;
  }

  .spec__name {
    padding-left: 5px;
  }

  .spec__value {
    width: 40%;
  }

  .spec__value {
    padding-left: 5px;
  }

  .checkoutOffer__item {
    text-align: center;
  }

  .checkoutOffer__item {
    padding-bottom: 20px;
  }

  .checkoutOffer__item--type--number {
    width: calc(50% - 30px);
  }

  .checkoutOffer__item--type--number {
    order: 0;
  }

  .checkoutOffer__item--type--number {
    display: block;
  }

  .checkoutOffer__item--type--number {
    height: 21px;
  }

  .checkoutOffer__item--type--number {
    line-height: 21px;
  }

  .checkoutOffer__item--type--number {
    padding-top: 10px;
  }

  .checkoutOffer__item--type--number {
    text-align: left;
  }

  .checkoutOffer__item--type--img {
    width: calc(100% - 30px);
  }

  .checkoutOffer__item--type--img {
    order: 2;
  }

  .checkoutOffer__item--type--content {
    width: calc(100% - 30px);
  }

  .checkoutOffer__item--type--content {
    order: 3;
  }

  .checkoutOffer__item--type--count {
    width: calc(100% - 30px);
  }

  .checkoutOffer__item--type--count {
    order: 4;
  }

  .checkoutOffer__item--type--del {
    width: calc(50% - 30px);
  }

  .checkoutOffer__item--type--del {
    order: 0;
  }

  .checkoutOffer__item--type--del {
    padding-top: 10px;
  }

  .checkoutOffer__item--type--del {
    align-self: center;
  }

  .checkoutOffer__img {
    margin: auto;
  }

  .imageDelete {
    top: 0;
  }

  .comment__user {
    width: calc(100% - 30px);
  }

  .comment__snippet {
    width: calc(100% - 30px);
  }

  .list-sinppet-obj__item {
    width: calc(100% - 30px);
  }

  .object-main__item {
    width: calc(100% - 30px);
  }

  .object-item__table td:first-child {
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__topMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__utpBlock {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__sliderTab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__breadcrumbs {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout__recProducts {
    padding-left: 15px;
    padding-right: 15px;
  }

  .snippet {
    width: calc(50% - 30px);
  }

  .modification__item {
    width: 100%;
  }
}